export interface ITag {
	name: string;
	title: string;
	uri: string;
}

export interface IState {
	tags: ITag[];
	numberToShow: number;
	total: number;
}

const deafultState: IState = {
	tags: [],
	numberToShow: 0,
	total: 0,
}

export default deafultState;
