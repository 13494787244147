
import Vue from 'vue';
import { IState, ITag } from './store/state';

// Interfaces for component typechecking!
interface AppData {
	limit: number;
}

interface AppMethods {
	showMore(): void;
	focusFirstNewElement(index: number): void;
}

interface AppComputed {
	numberToShow: number;
	tags: ITag[];
	total: number;
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	data() {
		return {
			limit: (this.$store.state as IState).numberToShow,
		}
	},
	computed: {
		numberToShow() {
			return (this.$store.state as IState).numberToShow;
		},
		tags() {
			return (this.$store.state as IState).tags.slice(0, this.limit);
		},
		total() {
			return (this.$store.state as IState).total;
		}
	},
	methods: {
		showMore() {
			let index = this.limit;
			this.limit = this.total;
			console.log(this.limit)
			console.log(this.tags)
      this.focusFirstNewElement(index);
    },
    focusFirstNewElement(index: number) {
      let links: NodeListOf<HTMLElement> = document.querySelectorAll('.hiq-tag-list__link');
      if(links[index]){
        links[index].focus();
      } else {
        setTimeout(() => { this.focusFirstNewElement(index) }, 100);
      }
    },
	},
	components: {

	}
})
